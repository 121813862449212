
export default class CustomMarker extends google.maps.OverlayView {
    constructor(latlng, map, args) {
        super();

        this.latlng = latlng;
        this.map = map;
        this.args = args;

        this.setMap(map);
    }

    draw() {

        let div = this.div;

        if(!div) {
            div = this.div = document.createElement('span');
            div.classList.add('c-map_marker');

            if (typeof(this.args.marker_id) !== 'undefined') {
                div.dataset.marker_id = this.args.marker_id;
            }

            const panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
        }
    }

    remove() {
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    }

    getPosition() {
        return this.latlng
    }

    setPosition(args) {

        this.latlng = new google.maps.LatLng(args.lat, args.lng);
    }
}
