import { module } from 'modujs';
import CustomMarker from './CustomMarker';
import style from '../style';

export default class extends module {
    constructor(m) {
        super(m);

        this.isValid = false;
        this.radius = 30;
    }

    init() {

        this.$distance = this.$('distance')[0];

        let goalPosition = new google.maps.LatLng(45.514177, -73.572239);
        let userPosition = new google.maps.LatLng(0, 0);

        const map = new google.maps.Map(this.$('instance')[0], {
            center: userPosition,
            zoom: 18,
            disableDefaultUI: true,
            styles: style
        });

        const marker = new CustomMarker(userPosition, map,{
            marker_id: 'lol'
        });

        const goal = new google.maps.Circle({
            center: goalPosition,
            map: map,
            fillColor: 'red',
            fillOpacity: 0.3,
            radius: this.radius,
            strokeColor: 'red',
            strokeWeight: 1
        });

        let distance = google.maps.geometry.spherical.computeDistanceBetween (goalPosition, userPosition);

        // Get user's location
        if ('geolocation' in navigator) {
            navigator.geolocation.watchPosition(
                position => {

                    // Set marker's position.
                    marker.setPosition({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude
                    });

                    // // Center map to user's position.
                    map.panTo({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude
                    });

                    userPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                    distance = google.maps.geometry.spherical.computeDistanceBetween (goalPosition, userPosition)

                    this.$distance.innerHTML = `${parseInt(distance)}m`;
                    if(distance < this.radius && !this.isValid) {
                        this.isValid = true;
                        alert('Congrats');
                    }
                    if(distance >= this.radius && this.isValid) {
                        this.isValid = false;
                    }
                },
                err => {
                    alert(`Error (${err.code}): ${err.message}`)
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        } else {
            // alert('Geolocation is not supported by your browser.');
        }

    }

}
